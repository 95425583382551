import { useContext, useEffect } from 'react';
import { UpdatesContext } from '../context/updatesContext';
import { Api } from '../api';

export const useGetLatestNotification = ({ flag }) => {
  const { dispatch } = useContext(UpdatesContext);

  useEffect(() => {
    if (flag) {
      let endPoint;
      if (window?.location?.pathname?.includes('/whitelabel/admin/')) {
        endPoint = Api.getNetworkNewNotificationSpecificToOrg();
      } else {
        endPoint = Api.getNetworkNewNotification();
      }
      endPoint.then((newNotification) => {
        if (newNotification && newNotification?.data?.detail?.networks) {
          dispatch({
            type: 'ALL_REQUEST',
            payload: { networks: newNotification?.data?.detail?.networks },
          });
        }
      });
    }
  }, [flag]);
};
