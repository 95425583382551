import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';

export default function LazyLoadImageProp({ imageSrc, className }) {
  return (
    <div className='flex'>
      <LazyLoadImage
        alt='hexitime_image'
        className={`${className ? className : 'h-9 w-9 border-2 rounded-full self-center'} object-cover`}
        src={imageSrc}
        effect='blur'
      />
    </div>
  );
}

LazyLoadImageProp.propTypes = {
  imageSrc: PropTypes.any,
  className: PropTypes.any,
};
