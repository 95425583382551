export const Svgs = ({ iconName, fillClass }) => {
  switch (iconName) {
    case 'PencilIcon': {
      return (
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='2.77 3.36 13.87 13.87' className='h-3 w-3'>
          <path
            d='M3.33331 16.6666H6.66665L15.4166 7.91665C15.8441 7.47165 16.08 6.8768 16.0738 6.2598C16.0676 5.6428 15.8197 5.05283 15.3834 4.61652C14.9471 4.18021 14.3572 3.93235 13.7402 3.92614C13.1232 3.91994 12.5283 4.15587 12.0833 4.58331L3.33331 13.3333V16.6666Z'
            stroke='currentColor'
            strokeWidth='1.125'
            strokeLinecap='round'
            strokeLinejoin='round'
            className={fillClass}
          ></path>
          <path
            d='M11.25 5.41675L14.5833 8.75008'
            stroke='currentColor'
            strokeWidth='1.125'
            strokeLinecap='round'
            strokeLinejoin='round'
            className={fillClass}
          ></path>
        </svg>
      );
    }
    case 'EyeIcon': {
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='size-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z'
            className={fillClass}
          />
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
            className={fillClass}
          />
        </svg>
      );
    }
    case 'RightArrowIcon': {
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='currentColor'
          className='size-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
            className={fillClass}
          />
        </svg>
      );
    }
    case 'LeftArrowIcon': {
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='currentColor'
          className='size-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
            className={fillClass}
          />
        </svg>
      );
    }
    case 'FolderIcon': {
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='currentColor'
          className='size-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z'
            className={fillClass}
          />
        </svg>
      );
    }
    case 'bellIcon': {
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className={`icon icon-tabler icons-tabler-outline icon-tabler-bell ${fillClass}`}
        >
          <path stroke='none' d='M0 0h24v24H0z' />
          <path d='M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6' />
          <path d='M9 17v1a3 3 0 0 0 6 0v-1' />
        </svg>
      );
    }
    case 'UnlockIcon': {
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className={fillClass}
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z'
          />
        </svg>
      );
    }
    case 'MailIcon': {
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className={fillClass}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
          <path d='M3 7l9 6l9 -6' />
        </svg>
      );
    }
    case 'Login': {
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className={fillClass}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M9 8v-2a2 2 0 0 1 2 -2h7a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2' />
          <path d='M3 12h13l-3 -3' />
          <path d='M13 15l3 -3' />
        </svg>
      );
    }
    case 'angleDown': {
      return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' className={fillClass}>
          <path d='M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z' />
        </svg>
      );
    }
    default:
      return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z'
            className={fillClass}
          />
          <path
            opacity='0.4'
            d='M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z'
            className={fillClass}
          />
        </svg>
      );
  }
};
