import React, { lazy, memo, Suspense, useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import RoutesFile from '../routes/RouteFile';
import PrivateRoute from './PrivateRoute';
import Loader from '../component/common/Loader';
import PropTypes from 'prop-types';
import Sidebar from '../layout/Sidebar';
import useWindowDimensions from '../utils/helper';
import { SidebarContext } from '../context/SidebarContext';
import adminRoute from './WhiteLabelAdminRoute';
import AdminPrivateRoute from './AdminPrivateRoute';
import { useGetLatestNotification } from '../hooks/useGetLatestNotification';
import { useConnectWebSocket } from '../hooks/useConnectWebSocket';
const NotFound = lazy(() => import('../container/404NotFound'));
const Login = lazy(() => import('../container/Login'));

const LoadSideBar = () => {
  const location = useLocation();
  return (
    <>
      {![
        '/login',
        '/reset-password',
        '/forgot-password',
        '/login/success',
        ...adminRoute.filter((route) => route.private == false).map((route) => route.path),
      ]?.includes(location?.pathname) && <Sidebar />}
    </>
  );
};

const RoutesList = memo(() => {
  const { width } = useWindowDimensions();
  const { setShow } = useContext(SidebarContext);
  const [getLatestNotificationFlag, SetLatestNotificationFlag] = useState(false);
  useGetLatestNotification({ flag: getLatestNotificationFlag });
  useConnectWebSocket({ flag: getLatestNotificationFlag });
  useEffect(() => {
    if (width < 1024) {
      setShow(false);
    }
  }, [width]);

  const hexitimeAdminRouteLength = RoutesFile.length;
  const hexitimeAdminRoute = RoutesFile.map((itm, key) => {
    return itm.private ? (
      <PrivateRoute
        key={key}
        exact={itm.exact}
        path={itm.path}
        name={itm.name}
        onlyAdmin={itm.onlyAdmin}
        component={itm.component}
      />
    ) : (
      <Route key={key} exact={itm.exact} path={itm.path} name={itm.name} component={itm.component} />
    );
  });
  const whiteLabelPartnerRoute = adminRoute.map((route, key) => {
    return route.private ? (
      <AdminPrivateRoute
        key={hexitimeAdminRouteLength + key}
        exact={route.exact}
        path={route.path}
        name={route.name}
        component={route.component}
      />
    ) : (
      <Route key={key} exact={route.exact} path={route.path} name={route.name} component={route.component} />
    );
  });

  useEffect(() => {
    const token = localStorage.getItem('white_label_admin_access_token');
    const detail = localStorage.getItem('white_label_admin_detail');
    const superAdminToken = localStorage.getItem('token');
    const isAdminPanel = window.location.pathname.includes('/whitelabel/admin');
    if (isAdminPanel && token && detail) {
      if (['admin', 'networkadmin', 'superadmin'].includes(...JSON.parse(detail)?.roles)) {
        SetLatestNotificationFlag(true);
      }
    } else if (!isAdminPanel && superAdminToken) {
      SetLatestNotificationFlag(true);
    }
  }, []);
  const routes = [...hexitimeAdminRoute, ...whiteLabelPartnerRoute];
  return (
    <Router>
      <LoadSideBar />
      <Suspense fallback={<Loader />}>
        <Switch>
          {routes}
          <Route exact path='/'>
            <Redirect to='/login' component={Login} />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
});

RoutesList.propTypes = {
  isShow: PropTypes.any,
  setShow: PropTypes.any,
};

export default RoutesList;
